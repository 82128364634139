/* eslint-disable no-undef */

export default () => {
  const forms = document.querySelectorAll('.js-formie-form')
  if (forms.length === 0) return

  const fillTrackingInputs = () => {
    const $trackingInputs = document.querySelectorAll('[name="fields[hubspotTracking]"]')
    // eslint-disable-next-line
    const hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1')

    for (let i = 0; i < $trackingInputs.length; i++) {
      $trackingInputs[i].value = hutk
    }
  }

  const getNextSibling = (elem, selector) => {
    // Get the next sibling element
    let sibling = elem.nextElementSibling

    // If there's no selector, return the first sibling
    if (!selector) return sibling

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling
      sibling = sibling.nextElementSibling
    }

  }

  const slugify = (str) => {
    if (!str) return str
    str = str.replace(/^\s+|\s+$/g, '') // trim leading/trailing white space
    str = str.toLowerCase() // convert string to lowercase
    str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-') // remove consecutive hyphens
    return str
  }

  const changeUrl = (nextURL='', nextTitle= '') => {
    // eslint-disable-next-line
    const nextState = { additionalInformation: 'Updated the URL with JS' }

    // This will create a new entry in the browser's history, without reloading
    window.history.pushState(nextState, nextTitle, nextURL)

    if (typeof dataLayer === 'object') {
      dataLayer.push({
        event : 'virtualPageView',  //fixed value you need to use as trigger in GTM
        virtualPagePath : nextURL,
        virtualPageTitle: nextTitle
      })
    }

    // send GA pageView
    if (typeof ga === 'function') {
      // eslint-disable-next-line no-undef
      ga('send', {
        hitType: 'pageview',
        page: nextURL
      })
    }
  }

  const changeProgress = (progressElement, currentStepDetails, formElement) => {
    if (!progressElement) return
    const progressStep = progressElement.querySelector('.js-progress-step')
    const progressWidget = progressElement.querySelector('.js-progress-widget')
    const progressWMarker = progressElement.querySelector('.js-progress-marker')
    const progressBack = progressElement.querySelector('.js-progress-back')

    const firstStepUrl = formElement.dataset.formPageUrl
    const firstStepTitle = formElement.dataset.formPageTitle

    if (progressStep === null ||
        progressWidget === null ||
        progressWMarker === null ||
        progressBack === null
    ) {
      return
    }

    const currentStep = currentStepDetails.nextPageIndex + 1
    const totalSteps = currentStepDetails.totalPages
    const formPagesTitles = JSON.parse(progressElement.dataset.pages)

    progressStep.textContent = currentStep
    progressWidget.style.width = ((currentStep * 100 / totalSteps) + '%')
    progressWMarker.style.left = ((currentStep * 100 / totalSteps) + '%')

    if (currentStep > 1) {
      progressBack.classList.remove('hidden')
    } else {
      progressBack.classList.add('hidden')
    }

    if (currentStepDetails.nextPageId === null) { // if form submitted and success message is displayed
      changeUrl(firstStepUrl + 'bedankt', 'Bedankt!')
    } else if (currentStep === 1) {
      changeUrl(firstStepUrl, firstStepTitle)
    } else {
      changeUrl(firstStepUrl + slugify(formPagesTitles[currentStep - 1]), formPagesTitles[currentStep - 1])
    }
  }

  const getWordCount = (wordString) => {
    let words = wordString.split(' ')
    words = words.filter((words) => {
      return words.length > 0
    }).length
    return words
  }

  let blacklist = []
  fetch('/signup/blacklist/get_domains', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/text'
    }
  })
      .then(response => response.text())
      .then(data => blacklist = data)
      .catch(error => console.error('Error:', error))

  forms.forEach((form) => {
    form.addEventListener('onFormieThemeReady', (e) => {

      e.detail.addValidator('fullname', ({ input }) => {
        if (input.classList.contains('js-rule-fullname')) {
          let count = getWordCount(input.value)
          return count >= 2
        }
        return true
      }, () => {
        return ('Vul je volledige naam in')
      })

      e.detail.addValidator('businessEmail', ({ input }) => {
        if (input.classList.contains('js-rule-business-email')) {
          if (blacklist !== undefined) {
            let domainMatch = input.value.match(/@(.*)/)
            if (domainMatch === null) {
              return true
            }
            let domain = domainMatch[1]
            return blacklist.indexOf(domain) === -1
          }
        }
        return true
      }, () => {
        return ('Vul een zakelijk e-mailadres in')
      })

      e.detail.addValidator('phone', ({ input }) => {
        if (input.classList.contains('js-rule-phone')) {
          const stringValue = input.value.toString()
          return stringValue.length >= 8
        }
        return true
      }, () => {
        return ('Telefoonnummer is te kort')
      })
    })

    form.addEventListener('onAfterFormieSubmit', (e) => {
      const progressElement = form.querySelector('.js-progress')
      if (progressElement) {
        changeProgress(progressElement, e.detail, e.target)
      } else {
        changeUrl(window.location.href + 'bedankt', 'Bedankt!')
      }

      if (e.detail.nextPageId === null) {
        const successBanner = getNextSibling(form.closest('.fui-i'),'.js-multiform-banner')
        if (successBanner !== null) {
          successBanner.classList.remove('hidden')
        }

        if (typeof dataLayer === 'object') {
            // eslint-disable-next-line no-undef
            dataLayer.push({
              event: 'submit_demo_form'
            })
          }
      }
    })
  })

  fillTrackingInputs()
}
